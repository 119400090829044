class AuthStorage {

    setToken(token: string) {
        if (token) {
            window.localStorage.setItem("_token", token)
        }
    }

    getToken() {
        return window.localStorage.getItem("_token")
    }

    removeToken() {
        window.localStorage.removeItem("_token")
        this.remove2FAVerified();
    }

    setClient(id: string) {
        if (id) {
            window.localStorage.setItem("_client", id)
        }
    }

    getClient() {
        return window.localStorage.getItem("_client")
    }

   setOffset(id: number) {
        window.localStorage.setItem("_offset", id.toString())
    }

    getOffset() {
        return window.localStorage.getItem("_offset")
    }

    setTimescale(timescale: string) {
        if (timescale) {
            window.localStorage.setItem("_timescale", timescale)
        }
    }

    getTimescale() {
        return window.localStorage.getItem("_timescale")
    }

    removeTimescale() {
        window.localStorage.removeItem("_timescale")
    }

    setErrorMessage(message: string) {
        if (message) {
            window.localStorage.setItem("_m", message)
        }
    }

    getErrorMessage() {
        return window.localStorage.getItem("_m")
    }

    removeErrorMessage() {
        window.localStorage.removeItem("_m")
    }

    set2FARequired(required: string) {
        if (required) {
            window.localStorage.setItem("_2faRequired", required)
        }
    }

    get2FARequired() {
        return window.localStorage.getItem("_2faRequired")
    }

    remove2FARequired() {
        window.localStorage.removeItem("_2faRequired")
    }

    set2FABeenVerified(required: string) {
        if (required) {
            window.localStorage.setItem("_2faVerified", required)
        }
    }

   remove2FAVerified() {
        window.localStorage.removeItem("_2faVerified")
    }

    get2FABeenVerified() {
        return window.localStorage.getItem("_2faVerified")
    }
}

export const WinStorage = new AuthStorage()
